import React from 'react'
import { Link, graphql } from 'gatsby'
import Meta from '../components/meta'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Sidebar from '../components/sidebar'
import WordsSearch from '../components/words-search'
import Breadcrumb from '../components/breadcrumb'

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
        host
        author
        tagline
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          slug
          publishDate
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          youtubeUrl
          tags {
            name
            slug
          }
        }
      }
    }
  }
`

const Blog = ({ data, pageContext, location }) => {
  const site = data.site
  const posts = data.allContentfulBlogPost.edges
  const { previousPagePath, nextPagePath } = pageContext
  const breadcrumbList = [{str: 'トップ', path: '/'}, {str: 'コンテンツ一覧'}]

  return (
    <Layout location={location}>
      <div style={{ background: '#fff' }}>
        <Meta
          site={site}
        />
        <div className="wrapper">
          <div className="articlesContainer">
            <div>
              <Breadcrumb breadcrumbList={breadcrumbList}/>
              <h2 className="section-headline">コンテンツ一覧</h2>
              <WordsSearch />
              <ul className="article-list">
                {posts.map(({ node }) => {
                  return (
                    <li key={node.slug}>
                      <ArticlePreview article={node} />
                    </li>
                  )
                })}
              </ul>
              <nav style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {previousPagePath && (
                    <Link to={previousPagePath}>
                      &lt;&lt; 前へ
                    </Link>
                  )}
                </div>

                <div style={{ justifySelf: 'flex-end' }}>
                  {nextPagePath && (
                    <Link to={nextPagePath}>
                      次へ &gt;&gt;
                    </Link>
                  )}
                </div>
              </nav>
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blog